@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #000;
  --secondary: #000;
  --success: #4ADE80;
  --danger: #F87171;
  --info: #4F46E5;
  --warning: #FBBF24;
  --gray100: #FAFAFA;
  --gray200: #F5F5F5;
  --gray300: #E5E5E5;
  --gray400: #D4D4D4;
  --gray500: #A3A3A3;
  --gray600: #737373;
  --gray700: #525252;
  --gray800: #404040;
  --gray900: #262626;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: var(--white);
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray100);
  min-height: 100vh;
}

.bg-gray200 {
  background-color: var(--gray200);
}